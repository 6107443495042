.drop {
  width: 50%;
  border: 2px #2e3440 solid;
  border-style: dashed;
  border-radius: 25px;
  padding-top: 33px;
}

.DropContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected {
  background: darkslategrey !important;
}

.clickable {
  cursor: pointer;
}

.margin {
  margin: 15px 0px;
}

.PredImg {
  width: 50%;
  height: 50%;
}

.modal {
  text-align: center;
}

@media (min-width: 320px) and (max-width: 480px) {
  .drop {
    width: 90%;
    border: 2px #2e3440 solid;
    border-style: dashed;
    border-radius: 25px;
    padding-top: 33px;
  }
}
