.h1 {
  padding: 0;
  margin-bottom: 0;
  text-align: left;
  letter-spacing: 0.1em;
  word-spacing: 0.02em;   

  font-size: 5em;
}

.card {
  width: 70%;
  padding: 2em;
  margin-bottom: 2em;
  margin-top: 2em;
  background-color: #FFFFFF;
  border-radius: 100px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: 0px 0px 0px rgb(231, 231, 231);

  /* text-align: left; */
  /* letter-spacing: 0.1em; */
  word-spacing: 0.02em;
}

.link {
  text-decoration: none;
}

/* @media (min-width: 320px) and (max-width: 480px) {
  .card {
    width: 70%;
    height: 360px;
    padding: 1%;
    background-color: #e5e9f0;
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
} */
